import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { LoggerService } from '../logger.service';
import { ToasterService } from '../toaster.service';
import { HttpException } from '../../exceptions/http.exception';

const httpOptions: IRequestOptions = {
    headers: new HttpHeaders(
        { 'Access-Control-Allow-Origin': '*' })
};

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

@Injectable({
    providedIn: 'root'
})
export class AppHttpClient {

    public constructor(private httpClient: HttpClient,
        private httpLog: LoggerService,
        private toasterService: ToasterService) {
    }

    public get<T>(url: string, options?: IRequestOptions): Observable<T> {
        const startTime = new Date().getTime();
        // /options = options ?? httpOptions;
        this.httpLog.silly('HTTP Request GET', url);
        return this.httpClient.get<T>(url, options).pipe(
            tap(res => this.httpLog.silly('HTTP Response GET', url, `Result - ${JSON.stringify(res)}`)),
            catchError(err => {
                this.httpLog.error('Error Response HTTP GET', url, `Error Result - ${JSON.stringify(err)}`);
                return throwError(err);
            }),
            map(res => this.mapResponse(res)),
            finalize(() => {
                const finishTime = new Date().getTime();
                const timeTaken = finishTime - startTime;
                this.httpLog.silly(`Response time for GET ${url} -`, `${timeTaken} ms`);
            })

        );
    }

    private mapResponse(res) {
        if (res) {
            if (res.success) {
                return res.data;
            } else {
                throw new HttpException(res.status, res.message, res.data);
            }
        }
    }

    public post<T>(url: string, params: object, options?: IRequestOptions): Observable<T> {
        const startTime = new Date().getTime();
        this.httpLog.silly('HTTP Request POST', url, `Params - ${JSON.stringify(params)}`);
        options = options ?? httpOptions;
        return this.httpClient.post<T>(url, params, options).pipe(
            tap(res => this.httpLog.silly('Response HTTP POST', url, `Params - ${JSON.stringify(params)}`, `Result - ${JSON.stringify(res)}`)),
            catchError(err => {
                this.httpLog.error('Error Response HTTP POST', url, `Params - ${JSON.stringify(params)}`, `Error Result - ${JSON.stringify(err)}`);
                return throwError(err);
            }),
            map(res => this.mapResponse(res)),
            finalize(() => {
                const finishTime = new Date().getTime();
                const timeTaken = finishTime - startTime;
                this.httpLog.silly(`Response time for POST ${url} -`, `${timeTaken} ms`);
            })
        );
    }

    public put<T>(url: string, params: object, options?: IRequestOptions): Observable<T> {
        const startTime = new Date().getTime();
        this.httpLog.silly('HTTP Request PUT', url, `Params - ${JSON.stringify(params)}`);
        options = options ?? httpOptions;
        return this.httpClient.put<T>(url, params, options).pipe(
            tap(res => this.httpLog.silly('Response HTTP PUT', url, `Params - ${JSON.stringify(params)}`, `Result - ${JSON.stringify(res)}`)),
            catchError(err => {
                this.httpLog.error('Error Response HTTP PUT', url, `Params - ${JSON.stringify(params)}`, `Error Result - ${JSON.stringify(err)}`);
                return throwError(err);
            }),
            map(res => this.mapResponse(res)),
            finalize(() => {
                const finishTime = new Date().getTime();
                const timeTaken = finishTime - startTime;
                this.httpLog.silly(`Response time for PUT ${url} -`, `${timeTaken} ms`);
            })
        );
    }

    public delete<T>(url: string, options?: IRequestOptions): Observable<T> {
        const startTime = new Date().getTime();
        this.httpLog.silly('HTTP Request DELETE', url);
        options = options ?? httpOptions;
        return this.httpClient.delete<T>(url, options).pipe(
            tap(res => this.httpLog.silly('HTTP Response DELETE', url, `Result - ${JSON.stringify(res)}`)),
            catchError(err => {
                this.httpLog.error('Error Response HTTP DELETE', url, `Error Result - ${JSON.stringify(err)}`);
                return throwError(err);
            }), map(res => this.mapResponse(res)),
            finalize(() => {
                const finishTime = new Date().getTime();
                const timeTaken = finishTime - startTime;
                this.httpLog.silly(`Response time for DELETE ${url} -`, `${timeTaken} ms`);
            })
        );
    }

}
