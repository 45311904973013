import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel, UserRegistrationRequestModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel, OTPLoginRequestModel, OtpResponseModel, ResetPasswordRequestModel } from '../../_models/auth.model';
import { AppHttpClient } from 'src/app/core/services/http-call/http-call.service';
import { DeviceType } from 'src/app/core/app.constants';
import { ESafeboxPlan } from 'src/app/core/enums/PlanType';


const API_USERS_URL = `${environment.apiUrl}v1/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: AppHttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
  }

  registerOtp(requestModel: UserRegistrationRequestModel): Observable<any> {
    return this.http.post<OtpResponseModel>(`${API_USERS_URL}/register/otp`, requestModel);
  }

  loginOtp(username: string, password: string): Observable<any> {
    return this.http.post<OtpResponseModel>(`${API_USERS_URL}/login/otp`, { username, password });
  }

  resendOtp(tokenId: string): Observable<any> {
    return this.http.post<OtpResponseModel>(`${API_USERS_URL}/otp/resend`, { tokenId });
  }


  verifyLoginOtp(requestModel: OTPLoginRequestModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, requestModel);
  }

  verifyForgotPasswordOtp(requestModel: OTPLoginRequestModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/forgotOtp/verify`, requestModel);
  }

  verifyRegistrationOtp(requestModel: OTPLoginRequestModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/register`, requestModel);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<OtpResponseModel> {
    return this.http.post<OtpResponseModel>(`${API_USERS_URL}/forgotPassword`, {
      email,
    });
  }

  updateProfile(profileDetails: UserModel) {
    return this.http.put<boolean>(`${API_USERS_URL}/profile`, profileDetails);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/changePassword`, {
      oldPassword,
      newPassword
    });

  }


  resetPassword(token: string, password: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/resetPassword`, {
      token,
      password
    });
  }

  resetPasswordForUser(resetPasswordRequestModel: ResetPasswordRequestModel) {
    return this.http.post<boolean>(`${API_USERS_URL}/resetPassword`, resetPasswordRequestModel);
  }

  // attachPlan(plan: ESafeboxPlan): Observable<AttachPlanResponseModel> {
  //   return this.http.post<AttachPlanResponseModel>(`${API_USERS_URL}/attachPlan`, {
  //     plan
  //   });
  // }

  getUserByToken(token): Observable<UserModel> {

    return this.http.get<UserModel>(`${API_USERS_URL}/profile`);
  }


  addDeviceToken(token: string, deviceType: DeviceType = DeviceType.WEB): Observable<void> {

    return this.http.post<void>(`${API_USERS_URL}/deviceToken`, {
      token,
      deviceType
    });
  }
}
