import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel, UserRegistrationRequestModel } from '../_models/user.model';
import { AuthModel, OTPLoginRequestModel, OtpResponseModel, ResetPasswordRequestModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DeviceType, Role } from 'src/app/core/app.constants';
import { ESafeboxPlan } from 'src/app/core/enums/PlanType';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private otpTokenStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}_token`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }


  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        throw err;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginOtp(username: string, password: string): Observable<OtpResponseModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginOtp(username, password).pipe(
      map((auth: OtpResponseModel) => {
        const result = this.setOtpTokenFromSessionStorage(auth);
        return auth;
      }),
      catchError((err) => {
        throw err;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  registerOtp(registrationInfo: UserRegistrationRequestModel): Observable<OtpResponseModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.registerOtp(registrationInfo).pipe(
      map((auth: OtpResponseModel) => {
        const result = this.setOtpTokenFromSessionStorage(auth);
        return auth;
      }),
      catchError((err) => {
        throw err;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  verifyLoginOtp(requestModel: OTPLoginRequestModel) {
    return this.authHttpService.verifyLoginOtp(requestModel).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        throw err;
      }), finalize(() => this.isLoadingSubject.next(false))
    );
  }

  verifyForgotPasswordOtp(requestModel: OTPLoginRequestModel) {
    return this.authHttpService.verifyForgotPasswordOtp(requestModel).pipe(
      catchError((err) => {
        throw err;
      }), finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resendOtp(tokenId: string) {
    return this.authHttpService.resendOtp(tokenId);
  }

  verifyRegistrationOtp(requestModel: OTPLoginRequestModel) {
    return this.authHttpService.verifyRegistrationOtp(requestModel).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        throw err;
      }), finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetPasswordForUser(resetPasswordRequestModel: ResetPasswordRequestModel) {
    return this.authHttpService.resetPasswordForUser(resetPasswordRequestModel);
  }

  // attachPlan(plan: ESafeboxPlan) {
  //   return this.authHttpService.attachPlan(plan);
  // }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: this.router.url
      }
    });
  }

  isAdmin() {
    return (this.currentUserValue.role == Role.SUPER_ADMIN || this.currentUserValue.role == Role.ADMIN);
  }

  canAccess(access: string) {
    // return (this.currentUserValue.role == Role.SUPER_ADMIN || this.currentUserValue.role == Role.RESTAURENT_ADMIN ||
    //   this.currentUserValue.access.includes(access));
  }

  can(access: string) {
    return (this.currentUserValue.role == Role.SUPER_ADMIN || this.currentUserValue.role == Role.RESTAURENT_ADMIN ||
      this.currentUserValue.access?.includes(access));
  }

  isModuleAdmin() {
    return (this.currentUserValue.role == Role.ADMIN);
  }

  profileImage() {

    if (!this.currentUserValue?.image) {
      return `url(./assets/media/users/blank.png)`;
    }
    return `url('${this.currentUserValue.image}')`;

  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  addDeviceToken(token: string): Observable<void> {

    this.isLoadingSubject.next(true);
    return this.authHttpService.addDeviceToken(token, DeviceType.WEB).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<OtpResponseModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.authHttpService.changePassword(currentPassword, newPassword);
  }

  updateProfile(model: UserModel) {
    return this.authHttpService.updateProfile(model);
  }

  //private 
  private setOtpTokenFromSessionStorage(authRequestModel: OtpResponseModel) {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (authRequestModel && authRequestModel.tokenId) {
      sessionStorage.setItem(this.otpTokenStorageToken, JSON.stringify(authRequestModel));
      return true;
    }
    return false;
  }

  getOtpToken() {
    return this.getOtpTokenFromSessionStorage()?.tokenId;
  }

  private getOtpTokenFromSessionStorage() {

    const tokenString = sessionStorage.getItem(this.otpTokenStorageToken);

    return JSON.parse(tokenString);
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAccessToken() {
    let auth = this.getAuthFromLocalStorage();
    return auth ? auth.token : null;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
